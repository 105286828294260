html {
	width:100%;
    height  : 100%;
    overflow: hidden;
    position: relative;
	margin: 0;
}

body {
	background-image: url(./images/lgi01a201411091900.jpg);
	width:100%;
    height  : 100%;
    overflow: auto;
    position: relative;
	margin: 0;
  }

.svg-container {
	height: 100%;
	width: 100%;
}

@media (min-aspect-ratio: 8/5) { 

	.svg-content {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color : #d3d3d3;
	}

    #container {
		display: block;
		margin: 0 auto;
		height: 100%;
	}

}

@media (max-aspect-ratio: 8/5) { 

	.svg-content {
		display: inline-block;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		background-color : #d3d3d3;
	}

    #container {
		display: block;
		margin: auto 0;
		width: 100%;
	}

}
